import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Table, Space, Select, Input, Spin, message, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { getOrderStatus } from "../../lib/helpers";

const { Option } = Select;

const ViewAppointmentHistory = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [historySearchTerm, setHistorySearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState({});
  const [appointmentHistory, setAppointmentHistory] = useState([]);
  const [filteredAppointmentHistory, setFilteredAppointmentHistory] = useState(
    []
  );
  const [selectedType, setSelectedType] = useState("");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const staffAuth = localStorage.getItem("staffticket");
  const doctorAuth = localStorage.getItem("doctorticket");
  const config = {
    headers: {
      Authorization: "Bearer " + (staffAuth || doctorAuth),
    },
  };
  const url = staffAuth
    ? `${apiBaseUrl}/patients/history`
    : `${apiBaseUrl}/patients/history/bydr`;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(url, config)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
          setFilteredDetails(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching appointments:", error);
        message.error("Failed to get appointment details! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filterAppointments = useCallback(
    debounce((searchTerm) => {
      let filtered = details;

      if (searchTerm.trim() !== "") {
        filtered = filtered.filter((user) => {
          const appointmentMatch = user?.appointmentIds?.some(
            (appointment) =>
              appointment?.appointmentNumber
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              appointment?.fullname
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              appointment?.mobile
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              appointment?.address
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase())
          );

          return (
            user?.fullname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user?.userID?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user?.phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            appointmentMatch
          );
        });

        const expandedKeys = filtered
          .filter(
            (appointment) =>
              appointment.appointmentIds &&
              appointment.appointmentIds.length > 0
          )
          .map((appointment) => appointment._id);
        setExpandedRowKeys(expandedKeys);
      } else {
        setExpandedRowKeys([]);
      }
      setFilteredDetails(filtered);
    }, 500),
    [details, searchTerm]
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterAppointments(searchTerm);
  };

  const handleResetFilters = () => {
    setSearchTerm("");
    setExpandedRowKeys([]);
    setFilteredDetails(details);
  };

  // History section
  const filterHistoryAppointments = useCallback(
    debounce((historySearchTerm, selectedType) => {
      let filtered = appointmentHistory;

      if (historySearchTerm.trim() !== "") {
        filtered = filtered.filter(
          (user) =>
            user?.doctor?.fullname
              ?.toLowerCase()
              .includes(historySearchTerm.toLowerCase()) ||
            user?.department?.department
              ?.toLowerCase()
              .includes(historySearchTerm.toLowerCase())
        );
      }

      if (selectedType) {
        filtered = filtered.filter(
          (appointment) =>
            appointment?.type?.toLowerCase() === selectedType.toLowerCase()
        );
      }

      setFilteredAppointmentHistory(filtered);
    }, 500),
    [appointmentHistory, historySearchTerm, selectedType]
  );

  const handleHistorySearch = (e) => {
    const historySearchTerm = e.target.value;
    setHistorySearchTerm(historySearchTerm);
    filterHistoryAppointments(historySearchTerm, selectedType);
  };

  const handleHistoryTypeChange = (value) => {
    setSelectedType(value);
    filterHistoryAppointments(historySearchTerm, value);
  };

  const handleHistoryResetFilters = () => {
    setHistorySearchTerm("");
    setSelectedType("");
    setFilteredAppointmentHistory(appointmentHistory);
  };

  const appointmentColumns = [
    {
      title: "SN",
      key: "sn",
      render: (_, __, index) => index + 1,
      width: 70,
    },
    {
      title: "Appointment Number",
      dataIndex: "appointmentNumber",
      key: "appointmentNumber",
    },
    {
      title: "Patient Name",
      dataIndex: "fullname",
      key: "patientName",
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (address) =>
        address ? address : <span className="text-red-500">N/A</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <span>{getOrderStatus(status)}</span>,
    },
    {
      title: "Doctor",
      dataIndex: ["appointmentHistory"],
      key: "doctor",
      render: (appointmentHistory) => {
        if (appointmentHistory && appointmentHistory.length > 0) {
          const lastElement = appointmentHistory[appointmentHistory.length - 1];
          return (
            lastElement.doctor?.fullname || (
              <span className="text-red-500">Unassigned</span>
            )
          );
        }
        return <span className="text-red-500">Unassigned</span>;
      },
    },
    {
      title: "Date",
      dataIndex: ["appointmentHistory"],
      key: "date",
      render: (appointmentHistory) => {
        if (appointmentHistory && appointmentHistory.length > 0) {
          const lastElement = appointmentHistory[appointmentHistory.length - 1];

          return lastElement.date ? (
            lastElement.date?.split("T")[0]
          ) : (
            <span className="text-red-500">-</span>
          );
        }
        return <span className="text-red-500">-</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <button
            type="button"
            onClick={() => toggleModal(record)}
            className="px-4 py-1.5 rounded-md bg-sky-500 text-white hover:bg-sky-600"
          >
            View Details
          </button>
        </Space>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    return (
      <Table
        bordered
        columns={appointmentColumns}
        dataSource={record.appointmentIds}
        rowKey="_id"
        pagination={{ pageSize: 5, style: { marginTop: "24px" } }}
        className="my-3 me-3"
        title={() => "Appointment Details"}
      />
    );
  };

  const columns = [
    {
      title: "UserID",
      dataIndex: "userID",
      key: "userID",
    },
    {
      title: "User's Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "DOB",
      dataIndex: "DOB",
      key: "DOB",
      render: (dob) => dob?.split("T")[0],
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (address) => (address ? address : "-"),
    },
    {
      title: "Appointments",
      key: "appointments",
      render: (_, record) => <span>{record.appointmentIds.length}</span>,
    },
  ];

  const toggleModal = (record) => {
    if (record && record._id) {
      setIsModalOpen(!isModalOpen);

      setAppointmentDetails(record);
      setAppointmentHistory(record.appointmentHistory);
      setFilteredAppointmentHistory(record.appointmentHistory);
    }
    setIsModalOpen(!isModalOpen);
  };

  const appointmentHistoryColumns = [
    {
      title: "SN",
      key: "sn",
      render: (_, __, index) => index + 1,
      width: 70,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Doctor's Name",
      dataIndex: ["doctor", "fullname"],
      key: "doctor",
      render: (doctor) =>
        doctor ? doctor : <span className="text-red-500">Unassigned</span>,
    },
    {
      title: "Department",
      dataIndex: ["department", "department"],
      key: "department",
      render: (department) =>
        department ? (
          department
        ) : (
          <span className="text-red-500">Unassigned</span>
        ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) =>
        date ? (
          date?.split("T")[0]
        ) : (
          <span className="text-red-500">Unassigned</span>
        ),
    },
    {
      title: "Referred By (dr)",
      dataIndex: ["refer", "fromDoctor", "fullname"],
      key: "fromDoctor",
      render: (doctor) =>
        doctor ? doctor : <span className="text-red-500">-</span>,
    },
    {
      title: "Referred From (Dep)",
      dataIndex: ["refer", "fromDepartment", "department"],
      key: "fromDepartment",
      render: (department) =>
        department ? department : <span className="text-red-500">-</span>,
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (address) => (address ? address : "-"),
    },
    {
      title: "Note",
      dataIndex: "notes",
      key: "notes",
      width: 320,
      render: (notes, record) => {
        if (notes === "Appointment Ended") {
          let endedBy;

          if (record.endedByDoctor) {
            endedBy = "Dr. " + record.endedByDoctor?.fullname;
          } else if (record.endedByStaff) {
            endedBy = record.endedByStaff?.username + " -staff";
          } else {
            endedBy = "Unknown";
          }
          return (
            <>
              <div>{notes}</div>
              <span className="text-orange-600 italic font-medium">
                by {endedBy}
              </span>
            </>
          );
        }

        return notes;
      },
    },
  ];

  return (
    <>
      <div className="container max-w-8xl mx-auto">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">
            Appointment History
          </h1>
          <div className="flex justify-between mt-3">
            <div>
              <Input
                placeholder="Search..."
                prefix={<SearchOutlined />}
                allowClear
                value={searchTerm}
                onChange={handleSearch}
                style={{ width: 300 }}
              />
              <Button
                type="default"
                onClick={handleResetFilters}
                style={{ marginLeft: "0.5rem" }}
              >
                Reset Filters
              </Button>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            expandable={{
              expandedRowRender,
              expandedRowKeys: expandedRowKeys,
              onExpandedRowsChange: setExpandedRowKeys,
              rowExpandable: (record) =>
                record.appointmentIds && record.appointmentIds.length > 0,
            }}
            pagination={false}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto ">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle">
              <div className="m-4">
                <div className="mb-3">
                  <h1 className="text-2xl font-bold decoration-gray-400">
                    Appointment Details{" "}
                    <span className="text-lg font-medium">
                      of {appointmentDetails.fullname}{" "}
                      <span className="italic">
                        ({appointmentDetails.appointmentNumber})
                      </span>
                    </span>
                  </h1>
                  <div className="flex justify-between mt-3">
                    <div>
                      <Input
                        placeholder="Search..."
                        prefix={<SearchOutlined />}
                        allowClear
                        value={historySearchTerm}
                        onChange={handleHistorySearch}
                        style={{ width: 250 }}
                      />
                      <Select
                        placeholder="Select Type"
                        allowClear
                        value={selectedType}
                        onChange={handleHistoryTypeChange}
                        style={{ width: 160, marginLeft: "0.5rem" }}
                      >
                        <Option value="">Select Type</Option>
                        <Option value="Request">Request</Option>
                        <Option value="Ongoing">Ongoing</Option>
                        <Option value="FollowUp">Follow Up</Option>
                        <Option value="Refer">Refer</Option>
                        <Option value="Ended">Ended</Option>
                      </Select>
                      <Button
                        type="default"
                        onClick={handleHistoryResetFilters}
                        style={{ marginLeft: "0.5rem" }}
                      >
                        Reset Filters
                      </Button>
                    </div>
                  </div>
                </div>
                <Table
                  className="rounded-md shadow-md"
                  dataSource={filteredAppointmentHistory}
                  columns={appointmentHistoryColumns}
                  rowKey="_id"
                  pagination={false}
                  loading={{
                    indicator: <Spin size="large" />,
                    spinning: loading,
                  }}
                />
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={() => toggleModal(null)}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-500 text-base font-medium text-white hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewAppointmentHistory;
